@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  margin-bottom: 24px;
}

.dropdown {
  position: relative;
  user-select: none;
  width: 100%;
  height: 64px;
  padding: 19px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.dropdownButton:focus {
  outline: none;
}

.dropdown .dropdownButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey3);
  font-family: Poppins;
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
  text-align: left;

  cursor: pointer;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dropdown svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdownContent {
  position: absolute;
  width: 100%;
  top: 110%;
  left: 0;
  box-sizing: border-box;
  z-index: 1;
  background: #191919;
  min-height: 100%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  border: none;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  border: 1px solid var(--foundation-grey-g-40, #e0e0e0);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.dropdown .dropdownContent::-webkit-scrollbar {
  display: none;
}

.dropdown .dropdownContent .dropdownItem {
  padding: 10px 22px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  font-family: Poppins;
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 100%;
  color: #f4f4f4;
}

.dropdownItem2 {
  margin: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  width: 100%;
  cursor: pointer;
}

.inputSection {
  height: 60px;
  padding: 0.5rem 0.5rem;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 1;
}

.inputSection > input {
  width: 100%;
  height: 100%;
  text-indent: 8px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 122%;
  letter-spacing: 0.01em;
  background: transparent;
  border: 0.5px solid #d8dce9;
  outline: none;
  box-sizing: border-box;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.label {
  color: var(--grey);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 5px;
  display: block;
}

.label > span {
  color: var(--red);
}

.errorMessage {
  color: var(--red);
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tip {
  color: var(--grey);
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .dropdown {
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 0 12px;
  }

  .dropdown .dropdownButton {
    font-size: 14px;
    line-height: 23px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .label,
  .inputSection > input,
  .dropdown .dropdownContent .dropdownItem {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown {
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 0 12px;
  }

  .dropdown .dropdownButton {
    font-size: 14px;
    line-height: 23px;
  }

  .tip,
  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .label,
  .inputSection > input,
  .dropdown .dropdownContent .dropdownItem {
    font-size: 14px;
    line-height: 23px;
  }
}
