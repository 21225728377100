.container {
  background: var(--Criterion-Black, #191919);

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container > img {
  width: 100%;
  margin-bottom: 15vh;
}
