@font-face {
  font-family: "Mazius Display";
  src: url("/src/Assets/Fonts/MaziusDisplay/Mazius Display Regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Mazius Display";
  src: url("/src/Assets/Fonts/MaziusDisplay/Mazius Display Italic.otf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Mazius Display";
  src: url("/src/Assets/Fonts/MaziusDisplay/Mazius Display Extraitalic.otf");
  font-style: italic;
  font-weight: 500;
}

/* @font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Regular.woff") format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Medium.woff") format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-MediumItalic.woff") format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-SemiBold.woff") format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-SemiBoldItalic.woff") format('woff');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Bold.woff") format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-BoldItalic.woff") format('woff');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Black.woff") format('woff');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-BlackItalic.woff") format('woff');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-ExtraBold.woff") format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-ExtraBoldItalic.woff") format('woff');
  font-style: italic;
  font-weight: 800;
}
*/

@font-face {
  font-family: "Space Grotesk";
  src: url("/src/Assets/Fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.woff")
    format("woff");
  /* src: url("/src/Assets/Fonts/Poppins/Poppins-Light.woff") format('woff'); */
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-SemiBoldItalic.ttf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/Assets/Fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: "Poppins", "Mazius Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", "Mazius Display", source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
