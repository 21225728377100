.slideIn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #191919;
  transform-origin: bottom;
}

.slideOut {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #191919;
  transform-origin: top;
}
