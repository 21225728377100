.container {
  position: relative;
  /* background: url("../../Assets/Images/grid.svg"); */
  background: #f4f4f4;
  background-position: center center;
}

.header {
  position: sticky;
  top: 0vh;
  z-index: 10;
  height: 74px;
}

.body {
  min-height: calc(100vh - 74px);
  overflow: hidden;
}
