.container {
	padding: 83px 120px 231px 120px;
	display: flex;
	align-items: flex-end;
	gap: 200px;
}

.container > div:nth-child(1) {
	flex-basis: 1;
}

.container > div:nth-child(1) > h4 {
	font-family: Poppins;
	font-size: 128px;
	font-weight: 400;
	line-height: 109.18px;
	letter-spacing: -0.04em;
	text-align: left;
	color: #191919;
}

.container > div:nth-child(1) > h4 > span {
	color: #a49031;
}

.container > div:nth-child(1) > p {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	letter-spacing: -0.01em;
	text-align: left;
	color: #191919;
	max-width: 475.25px;
}

.container > div:nth-child(1) > button {
	margin-top: 0.5rem;
	min-width: 160px;
}

.container > div:nth-child(2) {
	text-align: right;
}

.container > div:nth-child(2) > p {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 600;
	line-height: 22.97px;
	border-bottom: 0.5px solid #191919;
	/* padding-right: 33px; */
	display: inline-block;
	text-align: right;
}

.container > div:nth-child(2) > div {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 16px;
	row-gap: 0rem;
	flex-wrap: wrap;
	margin-top: 0.5rem;
}

.container > div:nth-child(2) > div > span {
	flex-basis: calc(50% - 22px);
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	letter-spacing: -0.01em;
	color: #191919;
}

@media screen and (max-width: 767px) {
	.container {
		padding: 53px 32px 80px 32px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 30px;
	}

	.container > div:nth-child(1) {
		flex-basis: 1;
	}

	.container > div:nth-child(1) > h4 {
		font-size: 48px;
		line-height: 42.24px;
		letter-spacing: -0.04em;
		text-align: left;
	}

	.container > div:nth-child(1) > p {
		font-size: 18px;
		line-height: 22.97px;
		max-width: 100%;
	}

	.container > div:nth-child(2) {
		display: none;
	}

	.container > div:nth-child(2) > p {
		padding-left: 20px;
		display: inline-block;
	}

	.container > div:nth-child(2) > div {
		justify-content: flex-start;
	}
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.container {
		padding: 53px 32px 80px 32px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 30px;
	}

	.container > div:nth-child(1) {
		flex-basis: 1;
	}

	.container > div:nth-child(1) > h4 {
		font-size: 48px;
		line-height: 42.24px;
		letter-spacing: -0.04em;
		text-align: left;
	}

	.container > div:nth-child(1) > p {
		font-size: 18px;
		line-height: 22.97px;
		max-width: 100%;
	}

	.container > div:nth-child(2) {
		opacity: 0;
	}

	.container > div:nth-child(2) > p {
		padding-right: 20px;
		display: inline-block;
	}

	.container > div:nth-child(2) > div {
		justify-content: flex-start;
	}
}
