.container {
	padding: 392px 162px 147px 162px;
	/* background: url("../../Assets/Images/greenbg.png"); */
	background: #30352f;
	background-position: center center;
}

.container > p {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 600;
	line-height: 22.97px;
	text-align: center;
	color: #f2f2f2;
}

.container > h2 {
	font-family: 'Poppins';
	font-size: 64px;
	font-weight: 300;
	line-height: 64px;
	letter-spacing: -0.02em;
	text-align: center;
	color: #f2f2f2;
	max-width: 1038.4px;
	margin: 40px auto;
}

.container img {
	max-width: 1114px;
	height: 504px;
	border-radius: 3px;
	margin: auto;
	display: block;
}

.container > div:nth-child(3) {
	width: 1114px;
	height: 504px;
	border-radius: 3px;
	margin: auto;
	display: block;
}

.container > div:nth-child(3) > div {
	height: 504px !important;
	width: 100% !important;
}

.container > button {
	margin: 40px auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.container {
		padding: 169px 32px 107px 32px;
		background: #30352f;
	}

	.container > p {
		font-size: 10px;
		font-weight: 400;
		line-height: 15px;
	}

	.container > h2 {
		font-size: 48px;
		font-weight: 300;
		line-height: 44px;
		letter-spacing: -0.02em;
		max-width: auto;
	}

	.container > div > img {
		max-width: 100%;
		height: 170.13px;
	}

	.container > div:nth-child(3) {
		width: 100%;
		height: 170.13px;
	}

	.container > div:nth-child(3) > div {
		height: 170.13px;
	}

	.container > button {
		margin: 40px auto;
	}
}

@media screen and (max-width: 767px) {
	.container {
		padding: 169px 32px 107px 32px;
		background: #30352f;
	}

	.container > p {
		font-size: 10px;
		font-weight: 400;
		line-height: 15px;
	}

	.container > h2 {
		font-size: 48px;
		font-weight: 300;
		line-height: 44px;
		letter-spacing: -0.02em;
		max-width: auto;
	}

	.container > div > img {
		max-width: 100%;
		height: 170.13px;
	}

	.container > div:nth-child(3) {
		width: 100%;
		height: 170.13px;
	}

	.container > div:nth-child(3) > div {
		height: 170.13px;
	}

	.container > button {
		margin: 40px auto;
	}
}
