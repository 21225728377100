@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container {
  margin-bottom: 24px;
}

.container > label {
  color: var(--grey);
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.container > span:nth-child(2) {
  color: var(--red);
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.input {
  position: relative;
  display: block;
}

.input > span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input > input {
  display: block;
  width: 100%;
  font-family: ""Poppins"";
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 5px 0;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 20px 19px;
  border: 1px solid #191919;
  color: #191919;
  background: transparent;
}

.input input:focus {
  outline: none;
  background: transparent;
}

.invalid {
  border: 1px solid red;
  color: red;
  background: transparent;
}

.input > .light::placeholder {
  font-family: Poppins;
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #191919;
}

.dark::placeholder {
  color: #f4f4f4;
}

.errorMessage {
  color: var(--red);
  font-family: Poppins;
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
  animation: appear 0.2s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tip {
  font-family: Poppins;
  font-size: 16.62px;
  font-weight: 300;
  line-height: 24.92px;
  letter-spacing: -0.01em;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .input > input {
    padding: 12px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    border-radius: 5px;
  }

  .container > label {
    font-size: 16px;
    line-height: 25px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .input > span {
    right: 10px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1023px) {
  .input > input {
    padding: 12px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    border-radius: 5px;
  }

  .container > label {
    font-size: 16px;
    line-height: 25px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .input > span {
    right: 10px;
  }
}
