.container {
	background: #191919;
	padding: 40px 0px 0px 0;
}

.topSection {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 48px 0px;
	margin: 0px 200px;
	border-bottom: 1px solid #f2f2f2;
	gap: 113px;
}

.topSection > p {
	font-family: 'Poppins';
	text-transform: uppercase;
	font-size: 22px;
	font-weight: 600;
	line-height: 30.62px;
	text-align: left;
	color: #ffffff;
	width: 241.51px;
}

.topSection > div {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.topSection > div > input {
	display: block;
	width: 100%;
	font-family: 'Poppins';
	font-size: 16.62px;
	font-weight: 300;
	line-height: 24.92px;
	letter-spacing: -0.01em;
	text-align: left;
	margin: 16px 0;
	animation: appear 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 20px 19px;
	color: #f2f2f2;
	background: transparent;
	outline: none;
	border-bottom: 1px solid #f2f2f2;
	border-right: none;
	border-left: none;
	border-top: none;
}

.topSection > div > button {
	max-width: 220px;
	align-self: flex-end;
}

.middleSection {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 2rem 200px;
}

.middleSection > div > a,
.middleSection > div > p {
	display: block;
	color: #f2f2f2;
	text-decoration: none;
}

.middleSection > div:nth-child(1) > a {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 600;
	line-height: 22.97px;
	text-align: left;
}

.middleSection > div:nth-child(2) {
	width: 297.38px;
}

.middleSection > div:nth-child(2) > a,
.middleSection > div:nth-child(2) > p {
	font-family: 'Poppins';
	font-size: 15.5px;
	font-weight: 400;
	line-height: 23.25px;
}

.middleSection > div:nth-child(3) {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 28px;
	width: 130px;
}

.middleSection > div:nth-child(3) > svg {
	width: 30px;
	height: 30px;
	color: #ffffff;
	flex-basis: 30px;
}

.logoSection {
	width: 100%;
	padding: 2rem 200px;
}

.logoSection > img {
	width: 100%;
	height: 100%;
}

.copySection {
	background: #30352f;
	padding: 18px;
	font-family: Poppins;
	font-size: 19.54px;
	font-weight: 300;
	line-height: 29.3px;
	text-align: center;
	color: #f2f2f2;
}

@media screen and (max-width: 767px) {
	.container {
		background: #191919;
		padding: 82px 0px 0px 0px;
	}

	.topSection {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0px 32px;
		margin: 0px 0px;
		border-bottom: none;
		gap: 32px;
	}

	.topSection > p {
		font-size: 24px;
		font-weight: 600;
		line-height: 30.62px;
		text-align: center;
		width: 100%;
	}

	.topSection > div {
		flex: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.topSection > div > input {
		display: block;
		width: 100%;
		font-size: 16px;
		line-height: 24.96px;
		letter-spacing: 0.02em;
		margin: 20px 0;
		padding: 8px 10px;
		color: #f2f2f2;
	}

	.topSection > div > button {
		align-self: center;
	}

	.middleSection {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 2rem 32px;
		gap: 40px;
		border-top: none;
	}

	.middleSection > div > a,
	.middleSection > div > p {
		display: block;
		color: #f2f2f2;
		text-decoration: none;
	}

	.middleSection > div:nth-child(1) > a {
		font-size: 18px;
		font-weight: 600;
		line-height: 22.97px;
		text-align: center;
		margin: 4px 0;
	}

	.middleSection > div:nth-child(2) {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		line-height: 23.04px;
	}

	.middleSection > div:nth-child(2) > a,
	.middleSection > div:nth-child(2) > p {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		line-height: 23.04px;
		margin: 4px 0;
		text-align: center;
	}

	.middleSection > div:nth-child(3) {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.logoSection {
		width: 100%;
		padding: 2rem 32px;
	}

	.logoSection > img {
		width: 100%;
		height: 100%;
	}

	.copySection {
		padding: 9.5px;
		font-size: 14px;
		font-weight: 300;
		line-height: 21px;
	}
}

@media screen and (min-width: 767px) and (max-width: 767px) {
	.container {
		background: #191919;
		padding: 82px 0px 0px 0px;
	}

	.topSection {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0px 32px;
		margin: 0px 0px;
		border-bottom: none;
		gap: 32px;
	}

	.topSection > p {
		font-size: 24px;
		font-weight: 600;
		line-height: 30.62px;
		text-align: center;
		width: 100%;
	}

	.topSection > div {
		flex: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.topSection > div > input {
		display: block;
		width: 100%;
		font-size: 16px;
		line-height: 24.96px;
		letter-spacing: 0.02em;
		margin: 20px 0;
		padding: 8px 10px;
		color: #f2f2f2;
	}

	.topSection > div > button {
		align-self: center;
	}

	.middleSection {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 2rem 32px;
		gap: 40px;
		border-top: none;
	}

	.middleSection > div > a,
	.middleSection > div > p {
		display: block;
		color: #f2f2f2;
		text-decoration: none;
	}

	.middleSection > div:nth-child(1) > a {
		font-size: 18px;
		font-weight: 600;
		line-height: 22.97px;
		text-align: center;
		margin: 4px 0;
	}

	.middleSection > div:nth-child(2) {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		line-height: 23.04px;
	}

	.middleSection > div:nth-child(2) > a,
	.middleSection > div:nth-child(2) > p {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		line-height: 23.04px;
		margin: 4px 0;
		text-align: center;
	}

	.middleSection > div:nth-child(3) {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.logoSection {
		width: 100%;
		padding: 2rem 32px;
	}

	.logoSection > img {
		width: 100%;
		height: 100%;
	}

	.copySection {
		padding: 9.5px;
		font-size: 14px;
		font-weight: 300;
		line-height: 21px;
	}
}
