.container {
  padding: 154px 291px 320px 291px;
  text-align: center;
  position: relative;
  background: #30352f;
  background-position: center center;
}

.textSection > h4 {
  font-family: "Mazius Display";
  font-size: 96px;
  font-weight: 400;
  line-height: 80.64px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #f2f2f2;
}

.textSection > h4 > span {
  color: #a49031;
  font-family: "Mazius Display";
  font-size: 96px;
  font-style: italic;
  font-weight: 400;
  line-height: 80.64px;
  letter-spacing: -0.04em;
  text-align: center;
}

.textSection > button {
  margin: 1.5rem auto;
}

.container > div > img {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1198px;
  min-width: 1000px;
  height: 316px;
  object-fit: cover;
  object-position: center;
}

.container > div > div {
  width: 1198px !important;
  height: 300px !important;
  position: absolute !important;
  top: 80% !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto;
  display: block !important;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 86px 28px;
  }

  .textSection > h4 {
    font-size: 40px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.04em;
  }

  .textSection > h4 > span {
    font-size: 40px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.04em;
  }

  .textSection > button {
    margin: 1rem auto;
  }
  .container > div:last-child {
    height: 266.24px !important;
    width: 80% !important;
    top: 80% !important;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    box-sizing: border-box;
  }

  .container > div > img,
  .container > div > div {
    margin: auto !important;
    width: 20% !important;
    height: 100% !important;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover !important;
    top: 0% !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 86px 28px;
  }

  .textSection > h4 {
    font-size: 40px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.04em;
  }

  .textSection > h4 > span {
    font-size: 40px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.04em;
  }

  .textSection > button {
    margin: 1rem auto;
  }

  .container > div:last-child {
    height: 266.24px !important;
    width: 80% !important;
    top: 80% !important;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    box-sizing: border-box;
  }

  .container > div > img,
  .container > div > div {
    margin: auto !important;
    width: 20% !important;
    height: 100% !important;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover !important;
    top: 0% !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }
}
