.container {
	border-bottom: 1px solid #191919;
	margin-bottom: 0.5rem;
}

.header {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	padding: 2rem 0;
	color: #191919;
	cursor: pointer;
	user-select: none;
	gap: 2rem;
}

.header > p:nth-child(1) {
	font-family: 'Poppins';
	font-size: 30px;
	font-weight: 400;
	line-height: 26.7px;
	text-align: left;
	flex-basis: 70%;
}

.header > p:nth-child(2) {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	letter-spacing: -0.01em;
	text-align: left;
}

.header > svg {
	transition: all 0.3s ease-in-out;
	flex: 0 0 auto;
	width: 22px;
	height: 22px;
	margin-left: auto;
}

.body {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	letter-spacing: -0.01em;
	text-align: left;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.body > p {
	padding: 1rem 0;
}

@media screen and (max-width: 767px) {
	.container {
		margin-bottom: 0.5rem;
	}

	.header {
		padding: 1rem 0;
	}

	.header > p:nth-child(1) {
		font-size: 18px;
		line-height: 22.97px;
	}

	.header > p:nth-child(2) {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.01em;
	}

	.body {
		font-size: 14px;
		line-height: 140%;
	}

	.body > p {
		padding: 0.5rem 0;
	}

	.header > svg {
		width: 14px;
		height: 14px;
	}
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.container {
		margin-bottom: 0.5rem;
	}

	.header {
		padding: 1rem 0;
	}

	.header > p:nth-child(1) {
		font-size: 18px;
		line-height: 22.97px;
	}

	.header > p:nth-child(2) {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.01em;
	}

	.body {
		font-size: 14px;
		line-height: 140%;
	}

	.body > p {
		padding: 0.5rem 0;
	}

	.header > svg {
		width: 14px;
		height: 14px;
	}
}
