.container {
  padding: 140px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #30352f;
  background-position: center center;
}

.container > div:first-child {
  border-top: 1px solid #f4f4f4;
}

.container > div {
  width: 80%;
  padding: 2rem 4rem;
  border-bottom: 1px solid #f4f4f4;
}

.container > div > h4 {
  font-family: "Mazius Display";
  font-size: 72px;
  font-style: italic;
  font-weight: 400;
  line-height: 88px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #f2f2f2;
}

.container > div > p {
  margin: 1rem 0;
  width: 559.04px;
  margin-left: 128px;
  color: #f2f2f2;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24.96px;
  letter-spacing: 0.02em;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 108px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container > div:first-child {
    border-top: 1px solid #f4f4f4;
  }

  .container > div {
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid #f4f4f4;
  }

  .container > div > h4 {
    font-size: 40px;
    font-style: italic;
    line-height: 88px;
    letter-spacing: -0.04em;
    text-align: left;
    max-width: auto;
  }

  .container > div > p {
    margin: 0rem 0;
    width: auto;
    margin-left: 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    max-width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 108px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container > div:first-child {
    border-top: 1px solid #f4f4f4;
  }

  .container > div {
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid #f4f4f4;
  }

  .container > div > h4 {
    font-size: 40px;
    font-style: italic;
    line-height: 88px;
    letter-spacing: -0.04em;
    text-align: left;
    max-width: auto;
  }

  .container > div > p {
    margin: 0rem 0;
    width: auto;
    margin-left: 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    max-width: 100%;
  }
}
