@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.container {
	padding: 100px 292px;
}

.container > h2 {
	font-family: 'Mazius Display';
	font-size: 96px;
	font-style: italic;
	font-weight: 400;
	line-height: 88px;
	letter-spacing: -0.04em;
	text-align: left;
	margin-bottom: 68px;
	text-align: center;
}

.container > div:nth-child(2) {
	display: flex;
	align-items: stretch;
	justify-content: center;
	animation: appear 0.3s ease-in-out;
}

.container > div > div > img {
	width: 248.16px;
	height: 410.8px;
	object-fit: cover;
}

.container > div > div > p:nth-child(2) {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 600;
	line-height: 22.97px;
	text-align: left;
	color: var(--Criterion-Black, #191919);
}

.container > div > div > p:nth-child(3) {
	font-family: 'Poppins';
	font-size: 12.02px;
	font-weight: 400;
	line-height: 13.2px;
	letter-spacing: -0.02em;
	text-align: left;
	margin: 0.25rem 0;
}

.container > div > div > p:nth-child(4) {
	font-family: 'Poppins';
	font-size: 12px;
	font-weight: 600;
	line-height: 15.31px;
	text-align: left;
	color: #191919;
	cursor: pointer;
	text-decoration: underline;
}

.details {
	display: flex;
	align-items: stretch;
	gap: 14px;
	animation: appear 2s ease-in-out;
}

.details > div {
	color: #f4f4f4;
	background: #191919;
	border: 1px solid #f8f8f8;
	padding: 10px 14px;
	min-height: 463.1px;
}

.details > img {
	object-fit: cover;
	width: 247.39px;
}

.details > div > h4 {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 600;
	line-height: 18.72px;
	text-align: left;
}

.details > div > h5 {
	font-family: 'Poppins';
	font-size: 14px;
	font-weight: 500;
	line-height: 15.37px;
	letter-spacing: -0.02em;
	text-align: left;
}

.details > div > div > p {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	text-align: left;
	margin-top: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.container {
		padding: 66px 28px;
	}

	.container > h2 {
		font-size: 40px;
		font-weight: 400;
		line-height: 35.2px;
		letter-spacing: -0.04em;
		margin-bottom: 2px;
		text-align: center;
	}

	.container > div {
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-direction: column;
		margin-top: 2rem;
	}

	.container > div:not(.details) {
		gap: 0.5rem;
	}

	.container > div > div > div > img {
		width: 100%;
		height: 473.8px;
		object-fit: cover;
	}

	.container > div > div > p:nth-child(2) {
		text-align: left;
	}

	.container > div > div > p:nth-child(3) {
		text-align: left;
		margin: 0.25rem 0;
	}

	.container > div > div > p:nth-child(4) {
		text-align: left;
	}

	.details {
		gap: 0px;
	}

	.details > img {
		width: 100%;
		height: 100%;
	}

	.details > div > div > p {
		margin-top: 1.5rem;
	}
}

@media screen and (max-width: 767px) {
	.container {
		padding: 66px 28px;
	}

	.container > h2 {
		font-size: 40px;
		font-weight: 400;
		line-height: 35.2px;
		letter-spacing: -0.04em;
		margin-bottom: 2px;
		text-align: center;
	}

	.container > div {
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-direction: column;
		margin-top: 2rem;
	}

	.container > div:not(.details) {
		gap: 0.5rem;
	}

	.container > div > div > div > img {
		width: 100%;
		height: 473.8px;
		object-fit: cover;
	}

	.container > div > div > p:nth-child(2) {
		text-align: left;
	}

	.container > div > div > p:nth-child(3) {
		text-align: left;
		margin: 0.25rem 0;
	}

	.container > div > div > p:nth-child(4) {
		text-align: left;
	}

	.details {
		gap: 0px;
	}

	.details > img {
		width: 100%;
		height: 100%;
	}

	.details > div > div > p {
		margin-top: 1.5rem;
	}
}
